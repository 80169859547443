export function hideZendeskWidget() {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    window.zE('webWidget', 'hide')
  }
}

export function openZendeskWidget() {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    window.zE('webWidget', 'show')
    // @ts-ignore
    window.zE('webWidget', 'open')
  }
}
