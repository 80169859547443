import type { CartItemSummary, CartSummary } from "~~/src/services/ecommerce/cartService"
import type { FetchError } from "ofetch"
import cartService from "~~/src/services/ecommerce/cartService"
import { useAuth } from "~/store/auth"

export const useCart = defineStore('ecommerce-cart', () => {
  const auth = useAuth()

  const cartIdLocalStorage = useLocalStorage<string>('cartId', null)
  const cartItemsLastUpdatedLocalStorage = useLocalStorage<string>('cartItemsLastUpdated', null)

  const cartIdPromise = ref<Promise<string>>()

  const cart = ref<CartSummary>()
  const items = ref<CartItemSummary[]>([])
  const loadingCart = ref(true)
  const loadingItems = ref(false)

  const cartId = computed(() => cart.value?.id)
  const lastUpdated = computed(() => cartItemsLastUpdatedLocalStorage.value)

  const loadCart = () => {
    cartIdPromise.value = new Promise(async (resolve, reject) => {
      try {
        var remoteCart: CartSummary
        if (auth.isAuthenticated) {
          remoteCart = await cartService.getSelfCart()
        } else if (cartIdLocalStorage.value) {
          try {
            remoteCart = await cartService.find(cartIdLocalStorage.value)
          } catch (error) {
            const fetchError = error as FetchError

            if (fetchError.status === 404 || fetchError.status === 403) {
              remoteCart = await cartService.createCart()
            } else {
              captureException(new Error('Failed to find cart', { cause: error}))

              throw error
            }
          }
        } else {
          remoteCart = await cartService.createCart()
        }

        cart.value = remoteCart
        cartIdLocalStorage.value = remoteCart.id
        loadingCart.value = false

        resolve(remoteCart.id)
      } catch(e) {
        reject(e)
      }
    })
  }

  const loadItems = async () => {
    if (loadingItems.value) return

    if (cartId.value) {
      loadingItems.value = true

      try {
        items.value = await cartService.listItems(cartId.value)
      } finally {
        loadingItems.value = false
      }
    }
  }

  const reload = async () => {
    await loadCart()
    await loadItems()
  }

  const addItem = async (productVariantId: number, quantity: number) => {
    const cartId = await cartIdPromise.value

    if (cartId) {
      await cartService.addItem(cartId, { productVariantId, quantity })

      cartItemsLastUpdatedLocalStorage.value = new Date().toISOString()
    }
  }

  const removeItem = async (cartItemId: string) => {
    const cartId = await cartIdPromise.value

    if (cartId) {
      await cartService.removeItem(cartId, cartItemId)

      cartItemsLastUpdatedLocalStorage.value = new Date().toISOString()
    }
  }

  const updateItem = async (cartItemId: string, quantity: number) => {
    if (quantity < 1) return await removeItem(cartItemId)

    const cartId = await cartIdPromise.value

    if (cartId) {
      await cartService.updateItem(cartId, cartItemId, { quantity })

      cartItemsLastUpdatedLocalStorage.value = new Date().toISOString()
    }
  }

  const clearCart = async () => {
    const cartId = await cartIdPromise.value

    if (cartId) {
      await cartService.clearCart(cartId)

      cartItemsLastUpdatedLocalStorage.value = new Date().toISOString()
    }
  }

  const mergeCart = async (cartIdToMerge: string) => {
    if (!auth.isAuthenticated) {
      throw new Error("Cannot merge cart when not authenticated")
    }

    if (items.value.length > 0 && cartId) {
      await cartService.mergeCart({ cartIdToMerge })

      loadCart()
    }
  }

  onMounted(async () => {
    loadCart()

    watch(() => cartId.value, async () => {
      await loadItems()
    }, { immediate: true })


    watch(() => cartItemsLastUpdatedLocalStorage.value, async () => {
      await loadItems()
    })

    watch(() => cartIdLocalStorage.value, async (newValue) => {
      const loadedValue = await cartIdPromise.value

      if (newValue !== loadedValue) {
        loadCart()
      }
    })
  })

  auth.$onAction(({ name, after }) => {
    if (name !== 'signIn') {
      return
    }

    const wasAuthenticated = auth.isAuthenticated

    after(async () => {
      if (auth.isAuthenticated && !wasAuthenticated && cartId.value) {
        await mergeCart(cartId.value)
      }
    })
  })

  return {
    cartId: skipHydrate(cartId),
    items,
    reload,
    addItem,
    updateItem,
    removeItem,
    clearCart,
    loading: loadingItems,
    lastUpdated
  }
})

