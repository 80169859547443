<template lang="pug">
.header
  .content
    NuxtLink(:to="{ name: 'home' }")
      img.header-logo(
        :src="logo",
        alt="Logo Box Magenta",
        width="112",
        height="90"
      )

    NavMenu
</template>
<script setup>
import logoBlackFriday from '~/assets/logo-black.svg'
import logoDefault from '~/assets/logo.svg'

const logo = computed(() => {
  if (isBlackFriday()) {
    return logoBlackFriday
  } else {
    return logoDefault
  }
})
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'

.header
  position: relative
  padding: 0 16px

  .content
    display: flex
    width: 100%
    max-width: 1440px
    margin: 0 auto
    align-items: center
    padding: 16px 0

    .header-logo
      flex-grow: 0
      width: auto
      height: 90px
      padding-right: 32px

    @include phablet
      .header-logo
        height: 64px
</style>
