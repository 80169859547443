import type { ProductVariantSummary } from './productVariantService'

export interface CartSummary {
  id: string
  customerId?: number
  createdAt: Date
  updatedAt: Date
}

export interface CartItemSummary {
  id: string
  cartId: number
  productVariant: ProductVariantSummary
  inventoryAvailability: CartItemInventoryAvailability
  quantity: number
  createdAt: Date
  updatedAt: Date
}

export enum CartItemInventoryAvailability {
  IN_STOCK = 'IN_STOCK',
  OUT_OF_STOCK = 'OUT_OF_STOCK'
}

export interface AddCartItemRequest {
  productVariantId: number
  quantity: number
}

export interface UpdateCartItemRequest {
  quantity: number
}

export interface MergeCartRequest {
  cartIdToMerge: string
}

export default {
  find(id: string): Promise<CartSummary> {
    return waffleFetch(`/ecommerce/cart/${id}`)
  },
  getSelfCart(): Promise<CartSummary> {
    return waffleFetch(`/customer/self/ecommerce/cart`)
  },
  mergeCart(request: MergeCartRequest): Promise<CartSummary> {
    return waffleFetch(`/customer/self/ecommerce/cart/merge`, {
      method: 'POST',
      body: request
    })
  },
  createCart(): Promise<CartSummary> {
    waffleFetch(`/ecommerce/cart`, {
      method: 'POST',
    })

    return waffleFetch(`/ecommerce/cart`, {
      method: 'POST',
      body: {}
    })
  },
  listItems(cartId: string): Promise<CartItemSummary[]> {
    return waffleFetch(`/ecommerce/cart/${cartId}/item`)
  },
  addItem(cartId: string, request: AddCartItemRequest): Promise<CartItemSummary[]> {
    return waffleFetch(`/ecommerce/cart/${cartId}/item`, {
      method: 'POST',
      body: request
    })
  },
  updateItem(cartId: string, cartItemId: string, request: UpdateCartItemRequest): Promise<CartItemSummary[]> {
    return waffleFetch(`/ecommerce/cart/${cartId}/item/${cartItemId}`, {
      method: 'PATCH',
      body: request
    })
  },
  removeItem(cartId: string, cartItemId: string): Promise<CartItemSummary[]> {
    return waffleFetch(`/ecommerce/cart/${cartId}/item/${cartItemId}`, {
      method: 'DELETE'
    })
  },
  clearCart(cartId: string): Promise<CartItemSummary[]> {
    return waffleFetch(`/ecommerce/cart/${cartId}/item`, {
      method: 'DELETE'
    })
  }
}